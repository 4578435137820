'use client';

import * as Sentry from '@sentry/nextjs';
import { ErrorPage } from '@unique/shared-library';

import { useEffect } from 'react';

export default function Error({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    if (Sentry.isInitialized()) {
      Sentry.captureException(error);
    }
  }, [error]);
  return <ErrorPage />;
}
